<template>
  <div>
    <div class="flex relative items-center w-full">
      <Input label="Swift code / Routing no" required p="p-2" class="mt-4 w-full"  placeholder="E.g 02334564" v-model="paymentFirstData.swiftCode" @blur="handleLookupValidation" @keyup="getLookUpData" maxlength="11"/>
      <span class="absolute bank-name text-xs font-semibold text-lightGrey-2" v-if="userBank.length">{{userBank | truncate(30)}}</span>
      <Spinner class="pt-8" v-if="swiftLookupLoading"/>
    </div>
    <p class="text-brandRed text-xs" v-if="routingErrorText.length">{{routingErrorText}}</p>
    <div v-show="!swiftLookupLoading && userBank.length">
      <Input  class="mt-4" label="Beneficiary's Country" required disabled placeholder="China" v-model="paymentFirstData.country" p="p-2"/>
      <Input v-if="paymentFirstData.countrySpecificData.collectIfcs" errorText="Fill this field, characters must be 11" ref="ifscInput"  :validation="rules.financialSystemCode" :maxlength="11" label="Indian financial system code (IFSC)" required p="p-2" class="mt-4"  placeholder="E.g 8e99j" v-model="paymentFirstData.financialSystemCode"/>
      <Input label="Beneficiary’s IBAN" required p="p-2" class="mt-4"  placeholder="EGXXXXXX" v-model="paymentFirstData.accountNumber" :validation="rules.accountNumber" v-if="paymentFirstData.countrySpecificData.collectIban" :errorText="`IBAN code must be ${paymentFirstData.countrySpecificData.ibanExpectedLength} characters`" ref="ibanInput"/>
      <Input label="Beneficiary’s account number" required p="p-2" class="mt-4" number placeholder="000000" v-model="paymentFirstData.accountNumber" v-else/>
     <Input label="Beneficiary’s name" required p="p-2" class="mt-4"  placeholder="John Doe" v-model="paymentFirstData.name"/>
     <Input number errorText="Fill this field, characters must be 10" :validation="rules.businessRegistrationNumber" v-if="paymentFirstData.countrySpecificData.collectBusinessRegistrationNumber" ref="businessRegInput" class="mt-4" label="Beneficiary’s business registration number" :maxlength="10" required placeholder="E.g 0109675432" v-model="paymentFirstData.businessRegistrationNumber" p="p-2"/>
     <Input label="Beneficiary’s email address(optional)"  p="p-2" class="mt-4"  placeholder="example@domain.com" type="email" v-model="paymentFirstData.email"/>
     <TelInput label="Beneficiary’s Phone number(optional)"  p="p-2" class="mt-4" number  placeholder="(406) 000-0000" type="email" v-model="paymentFirstData.phoneNumber" @valid="isPhoneValid"/>
     <Input label="Beneficiary’s address" required p="p-2" class="mt-4"  placeholder="Enter beneficiary's address" type="email" v-model="paymentFirstData.address" id="address"/>
      <Button text="Continue"  class="mt-4" @click="handleNextStep" :disabled="disableButton" />
    </div>
   
  </div>
</template> paymb
<script>
  import {mapActions, mapState} from 'vuex'
  import { Input,  CurrencyConverterInput, CurrencyInput ,TelInput} from "@/UI/Input";
  import { Button } from "@/UI/Button";
  import Spinner from '@/UI/Spinner'
  import {InlineToast} from '@/UI/Notification'
  import {LOOKUP_SWIFT} from '@/utils/api/wire'

  export default {
    components: {
      Input,
      Button,
      CurrencyConverterInput,
      Spinner,
      CurrencyInput,
      InlineToast,
      TelInput
    },
    props:{
      userData:{
        type: Object,
        default: () => {}
      },
      wireAccountData:{
            type: Object,
            default:() => {}
      }
    },
    data: () => ({
      convertFromValue: 0,
      swiftCode:'',
      swiftLookupLoading: false,
      country:'',
      userBank:'',
      currencyTo:'',
      currencySigns:{},
      paymentFirstData:{
        // bankId:'',
        swiftCode:'',
        country:'',
        accountNumber: '',
        accountName:'',
        email:'',
        phoneNumber:'',
        address:'',
        name:'',
        intlBankId:'',
        countrySpecificData:{},
        businessRegistrationNumber:'',
        financialSystemCode:''

      },
      isPhoneNumberValid: false,
      routingErrorText:'',
      timer:null
    }),
   
    computed:{
      ...mapState({
            // currencies: (state) => state?.dashboard?.dashboardData.currencyMappings,
            // walletBalance:(state) => state?.dashboard.dashboardData?.wallet
      }),
      disableButton(){
        return Object.values(this.rules).includes(false);
      },
      rules(){
        return{
          swiftCode: this.paymentFirstData.swiftCode.length > 6,
          country: !!this.paymentFirstData.country.length,
          userBank: !!this.userBank.length,
          // recieveAmount: this.paymentFirstData.receiveAmount >= 1000,
          accountNumber: (this.paymentFirstData.countrySpecificData && this.paymentFirstData.countrySpecificData.collectIban) ? this.paymentFirstData.accountNumber.length == this.paymentFirstData.countrySpecificData.ibanExpectedLength :  !!this.paymentFirstData.accountNumber,
          // email:  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          //           this.paymentFirstData.email.trim()
          //       ),
          // phoneNumber: this.isPhoneNumberValid,
          address: !!this.paymentFirstData.address.length,
          name: !!this.paymentFirstData.name.length,
          businessRegistrationNumber: this.paymentFirstData.countrySpecificData.collectBusinessRegistrationNumber ? this.paymentFirstData.businessRegistrationNumber.length == 10 : true,
          financialSystemCode: this.paymentFirstData.countrySpecificData.collectIfcs ? this.paymentFirstData.financialSystemCode.length == 11 : true
        }
      },
    },
    methods: {
      // ...mapActions('payments', ['setFirstPaymentData', 'getFees']),
      handleNextStep() {
        // this.setFirstPaymentData(this.paymentFirstData)
        this.$emit("nextStep", {data:this.paymentFirstData, step: 'two'});
      },
      handleLookupSwift(){
        this.userBank = ''
        this.paymentFirstData.country = ''
        if(this.paymentFirstData.swiftCode.length >= 8){
            this.swiftLookupLoading = true;
            this.routingErrorText = ''
            LOOKUP_SWIFT(this.paymentFirstData.swiftCode)
              .then(({data}) => {
                // console.log(response, 'response')
                this.paymentFirstData.country = data.data.beneficiaryBankDetails.country.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
                this.userBank = data.data.beneficiaryBankDetails.name;
                this.paymentFirstData.intlBankId = data.data.beneficiaryBankDetails.id;
                this.paymentFirstData.countrySpecificData = data.data.countrySpecificData
                this.swiftLookupLoading = false
              }).catch((error) => {
                this.swiftLookupLoading = false
                if(error?.response?.data?.message){
                  this.routingErrorText = error?.response?.data?.message
                }else{
                  this.routingErrorText = 'Technical difficulties. Please, try again'
                }
              })
        }
    
      },
      getLookUpData(){
        let functionToCall;
        const showError = () => {
          this.routingErrorText = 'Incomplete digits'
        }
        if(this.paymentFirstData.swiftCode.length < 8){
          functionToCall = showError
        }else{
          functionToCall = this.handleLookupSwift
        }
        
        this.debounce(functionToCall, 1500)
      },
      debounce(func, time){
          if (this.timer) {
            clearTimeout(this.timer); // clear any pre-existing timer
          }
          // const context = this; // get the current context
          this.timer = setTimeout(() => {
            func(); // call the function if time expires
          }, time);
        },
      handleLookupSwiftCode(){
        this.handleLookupSwift()
        // this.debounce(functionToCall, 1000)
      },
      isPhoneValid(value) {
        this.isPhoneNumberValid = value;
      },
      googleAutocomplete() {
        document.getElementById("address").placeholder = "Enter beneficiary's address";
       
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById("address"),
            // options
        );
        this.autocomplete.addListener("place_changed", () => {
            // this.isAddressValid = true;
            // this.validateAddress()
            let place = this.autocomplete.getPlace();
            // console.log(place);
            let ac = place.address_components;
                const street = {
                no: "",
                name: "",
                neighborhood: "",
            };

            this.paymentFirstData.address = place.formatted_address;
            });
        },
        handleLookupValidation(){
          
          if(this.paymentFirstData.swiftCode.length < 8){
            this.userBank = ''
            this.paymentFirstData.country = ''
            this.routingErrorText = 'Incomplete digits'
          }
        }
    },
    mounted(){
      this.googleAutocomplete();
      if(Object.keys(this.userData).length){
        Object.keys(this.paymentFirstData).forEach((key) => {
          if(this.userData.beneficiary[key]) this.paymentFirstData[key] = this.userData.beneficiary[key]
          this.paymentFirstData.swiftCode = this.userData.beneficiary.routingNumber || this.userData.beneficiary.swiftCode;
          this.userBank = this.userData.beneficiary.bankName
          this.$nextTick(() => {
            if(this.$refs.ifscInput)this.$refs.ifscInput.displayInput()
            if(this.$refs.businessRegInput)this.$refs.businessRegInput.displayInput()
            if(this.$refs.ibanInput)this.$refs.ibanInput.displayInput()
          }, 300)
         
        })
      }
      else{
        this.paymentFirstData = {
            // bankId:'',
            swiftCode:'',
            country:'',
            accountNumber: '',
            accountName:'',
            email:'',
            phoneNumber:'',
            address:'',
            name:'',
            intlBankId:'',
            countrySpecificData:{},
            businessRegistrationNumber:'',
            financialSystemCode:'',
            id: null
        }
      }
    }
  };
</script>
<style scoped>
.bank-name{
  right: 1rem;
  top: 3.7rem;
  z-index: 5
}
</style>